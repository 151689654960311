.markdown-content {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.6;
    color: #333;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    line-height: 1.25;
}

.markdown-content h1 {
    font-size: 2em;
}

.markdown-content h2 {
    font-size: 1.5em;
}

.markdown-content h3 {
    font-size: 1.25em;
}

.markdown-content h4 {
    font-size: 1em;
}

.markdown-content h5 {
    font-size: 0.875em;
}

.markdown-content h6 {
    font-size: 0.85em;
}

.markdown-content p {
    margin-top: 0;
    margin-bottom: 16px;
}

.markdown-content ul,
.markdown-content ol {
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: 2em;
}

.markdown-content ul {
    list-style-type: disc;
}

.markdown-content ol {
    list-style-type: decimal;
}

.markdown-content li+li {
    margin-top: 0.25em;
}

.markdown-content a {
    color: #0366d6;
    text-decoration: none;
}

.markdown-content a:hover {
    text-decoration: underline;
}

.markdown-content table {
    border-collapse: collapse;
    margin-bottom: 16px;
    width: 100%;
}

.markdown-content table th,
.markdown-content table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

.markdown-content table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
}

.markdown-content table tr:nth-child(2n) {
    background-color: #f6f8fa;
}